import React from 'react';
import { Route, Routes } from 'react-router-dom';
// Switch;
import HomePage from '../pages/HomePage';
import NumberPage from '../pages/NumberPage';
import BananerPage from '../pages/BananerPage';
import PicPage from '../pages/PicPage';

function RouteList(props) {
	console.log('this', props);
	return (
		<Routes>
			<Route
				exact
				path="/numberPage"
				element={<NumberPage></NumberPage>}
			/>
			<Route exact path="/picPage" element={<PicPage></PicPage>} />
			<Route
				exact
				path="/bananerPage"
				element={<BananerPage></BananerPage>}
			/>
			<Route path="/" element={<HomePage></HomePage>} />
		</Routes>
	);
}

export default RouteList;
