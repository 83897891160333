/* eslint-disable no-empty */
/**
 * @author FFET
 * @since 2021-01-04
 * @description request
 */

import { BASE_API } from '../config';
// import { Toast as ToastMobile } from 'antd-mobile';
// import NProgress from 'nprogress';
// import 'nprogress/nprogress.css';

// let requestUrls = [];

/**
 * 移除loading原因，loading原因数量为0时关闭loading
 * @param {string} reason loading原因
 */
export const loadingSwitch = (reason) => {
	// requestUrls.includes(reason) &&
	// 	requestUrls.splice(requestUrls.indexOf(reason), 1);
	// if (!requestUrls.length) NProgress.done();
};

/**
 * 增加loading原因
 * @param {string} reason 原因
 * @param {Boolean} openLoading 是否直接开启loading
 */
export const addLoadingReason = (reason, openLoading) => {
	// openLoading && NProgress.start();
	// requestUrls.push(reason);
};

/**
 * request
 * @param {*} config
 */
async function feathFunction({
	url,
	method = 'POST',
	data = {},
	headers = { 'Content-Type': 'application/json' },
	loading = true,
	timeout = 60 * 1000,
	config = {},
}) {
	const fetchPromise = new Promise((resolve, reject) => {
		let requestConfig = {
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		};

		if (!url) return;
		url = BASE_API + '/' + url;

		// token
		if (localStorage.getItem('token')) {
			requestConfig.headers.utoken = localStorage.getItem('token');
		}

		// header
		if (Object.keys(headers).length !== 0) {
			Object.assign(requestConfig.headers, headers);
		}

		// common header
		// header 里公共参数
		requestConfig.headers = {
			...requestConfig.headers,
		};

		// method & data
		if (
			method.toLowerCase() === 'post' ||
			method.toLowerCase() === 'delete'
		) {
			// form or json
			const contentType = requestConfig.headers['Content-Type'];
			if (contentType === 'application/json') {
				Object.defineProperty(requestConfig, 'body', {
					value: JSON.stringify(data),
				});
			} else if (contentType === 'x-www-form-urlencoded') {
				// 表单文件上传
				const form = new FormData();
				Object.keys(data).forEach((key) => {
					form.append(key, data[key]);
				});
			} else if (contentType === 'multipart/form-data') {
				Object.defineProperty(requestConfig, 'body', {
					value: data,
				});
				delete requestConfig.headers['Content-Type'];
			}
		} else if (method.toLowerCase() === 'get') {
			const str = Object.entries(data)
				.reduce((acc, cur) => acc.concat(cur.join('=')), [])
				.join('&');
			url += '?' + str;
		}
		!config.unLoading && addLoadingReason(url, true);

		// 浏览器中使用fetch请求
		fetch(url, requestConfig)
			.then((response) => {
				!config.unLoading && loadingSwitch(url);
				switch (response.status) {
					case 401:
						break;
					case 403:
						break;
					case 404:
						reject(new Error('接口未找到'));
						break;
					case 500:
						reject(new Error('服务器错误'));
						break;
					default:
						return response;
				}
			})
			.then((response) => {
				// 解析数据
				const type = response.headers.get('Content-Type').split(';')[0];
				let responseData;
				switch (type) {
					// switch (requestConfig.headers.Accept) {
					// json
					case 'application/json':
						responseData = response.json();
						break;
					// 文本
					case 'text/html':
						responseData = response.text();
						break;
					// 文件下载
					case 'application/octet-stream':
						// eslint-disable-next-line no-case-declarations
						const blob = response.blob();
						responseData = {
							blob,
							filename: response.headers.get(
								'Content-Disposition'
							),
							type: 'file',
						};

						break;
					default:
						break;
				}
				return responseData;
			})
			.then((data) => {
				resolve(data);
			});
	});

	// timeout promise
	const timeoutPromise = new Promise(function (resolve, reject) {
		const time = setTimeout(() => {
			clearTimeout(time);
			!config.unLoading && loadingSwitch(url);
			reject(new Error('请求超时'));
		}, timeout);
	});

	// 小菊花
	// loading && !config.unLoading && NProgress.start();

	try {
		const result = await Promise.race([fetchPromise, timeoutPromise]);
		// Toast.hide();

		if (result.type === 'file') {
			!config.unLoading && loadingSwitch(url);
			let { blob, filename } = result;
			blob.then((data) => {
				const blob = new Blob([data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
				});
				const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
					ie11 =
						navigator.userAgent.match(/Trident\/7.0/) &&
						navigator.userAgent.match(/rv:11/),
					ieEDGE = navigator.userAgent.match(/Edge/g),
					ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;
				console.log('ie:' + ie);

				console.log('ieVer:' + ieVer);

				if (ie && ieVer < 10) {
					this.message.error('No blobs on IE<10');
					return;
				}
				if (ieVer > -1) {
					window.navigator.msSaveBlob(blob, filename.split('=')[1]);
				} else {
					const url = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.setAttribute('href', url);
					link.setAttribute('download', filename.split('=')[1]);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			});
		} else {
			!config.unLoading && loadingSwitch(url);
			// 错误信息收集 发送到服务器
			if (result.code !== 100) {
				// 402 token过期了
				if (result.code === '402' || result.code === '40054') {
				} else {
					// ToastMobile.fail(result.msg || result.errmsg);
				}
			} else {
				// 业务逻辑code 0为成功
				result.status = true;
			}
			return result;
		}
	} catch (error) {
		console.log('catch', error);
		// 错误信息收集 发送到服务器
	} finally {
		// !requestUrls.length && NProgress.remove();
	}
}

export default feathFunction;
