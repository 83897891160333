import React, { Component } from 'react';
import './index.scss';
import { getNumberBanner, saveNumberBanner } from '../../request/api';
import FileUpload from '../../components/FileUpload';

export default class BananerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageList: [],
		};
	}

	componentDidMount() {
		this.getNumberBannerFn();
	}

	getNumberBannerFn = async () => {
		const res = await getNumberBanner();
		if (res.code === 100 && res.data) {
			this.setState({
				pageList: [res.data],
			});
		}
	};

	handleSaveBanner = async (image) => {
		const res = await saveNumberBanner({ image });
		if (res.code === 100 && res.data) {
			this.getNumberBannerFn();
		}
	};

	render() {
		const { pageList } = this.state;

		return (
			<div className="bananer-page">
				<h3>bananer列表</h3>

				{/* 上传文件 */}
				<div>
					<FileUpload savePic={this.handleSaveBanner}></FileUpload>
				</div>

				{pageList && pageList.length !== 0 && (
					<div className="my-table">
						<table>
							<thead>
								<tr>
									<td>封面图片</td>
								</tr>
							</thead>
							<tbody>
								{pageList.map((item) => {
									return (
										<tr key={item.id}>
											<td>
												<img
													src={item.image}
													alt=""
												></img>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}
