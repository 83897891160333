import React from 'react';
import { uploadFile } from '../../request/api';
const FileUpload = (props) => {
	// const [file, setFile] = useState(null);

	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		files.forEach((file) => {
			handleUpload(file);
		});
	};

	const handleUpload = async (file) => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			const res = await uploadFile(formData);
			if (res.code === 100) {
				console.log('File uploaded successfully');
				props.savePic(res.data);
			} else {
				console.error('Upload failed');
			}
		}
	};

	// const handleSave = () => {
	// 	props.savePic();
	// };

	return (
		<div>
			<input type="file" multiple onChange={handleFileChange} />
			{/* <button onClick={handleUpload}>上传</button> */}
			{/* <button onClick={handleSave}>保存</button> */}
		</div>
	);
};

export default FileUpload;
