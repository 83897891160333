import React, { Component } from 'react';
import './index.scss';
import {
	getNumberPic,
	saveNumberPic,
	deleteNumberPic,
	deleteNumberPicByType,
} from '../../request/api';
import FileUpload from '../../components/FileUpload';

const tabName = {
	1: '澳门时乐彩',
	2: '香港新彩',
	3: '澳门博彩',
};
export default class PicPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageList: [],
			activeTab: 1,
		};
	}

	componentDidMount() {
		this.getNumberPicFn();
	}

	getNumberPicFn = async () => {
		const res = await getNumberPic({ type: this.state.activeTab });
		if (res.code === 100 && res.data) {
			this.setState({
				pageList: res.data,
			});
		}
	};

	handleSavePic = async (image) => {
		const res = await saveNumberPic({
			image,
			type: this.state.activeTab,
		});
		if (res.code === 100 && res.data) {
			this.getNumberPicFn();
		}
	};
	deleteNumberPicFn = async (id) => {
		const res = await deleteNumberPic(id);
		if (res.code === 100 && res.data) {
			this.getNumberPicFn();
		}
	};
	deleteNumberPicByTypeFn = async () => {
		const res = await deleteNumberPicByType(this.state.activeTab);
		if (res.code === 100 && res.data) {
			this.getNumberPicFn();
		}
	};

	changeTab(activeTab) {
		activeTab = Number(activeTab);
		if (activeTab === this.state.activeTab) return;
		this.setState(
			{
				activeTab,
			},
			() => {
				this.getNumberPicFn();
			}
		);
	}

	render() {
		const { pageList } = this.state;

		return (
			<div className="pic-page">
				<h3>图片列表</h3>

				{/* 上传文件 */}
				<div className="page_header">
					<select onChange={(e) => this.changeTab(e.target.value)}>
						{tabName &&
							Object.keys(tabName).map((tab) => {
								return (
									<option
										key={tab}
										value={tab}
										label={tabName[tab]}
									></option>
								);
							})}
					</select>

					<FileUpload savePic={this.handleSavePic}></FileUpload>

					<button onClick={this.deleteNumberPicByTypeFn}>删除</button>
				</div>

				{pageList && pageList.length !== 0 && (
					<div className="my-table">
						<table>
							<thead>
								<tr>
									<td>封面图片</td>
									<td>操作</td>
								</tr>
							</thead>
							<tbody>
								{pageList.map((item) => {
									return (
										<tr key={item.id}>
											<td>
												<img
													src={item.image}
													alt=""
													loading="lazy"
												></img>
											</td>
											<td
												onClick={() =>
													this.deleteNumberPicFn(
														item.id
													)
												}
											>
												删除
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}
