import React, { Component } from 'react';
import './index.scss';
import { saveNumber, getNumberSys } from '../../request/api';

const tabName = ['', '澳门时乐彩', '香港新彩', '澳门博彩'];
function getWeekDay(dateStr) {
	try {
		const weekDays = [
			'星期天',
			'星期一',
			'星期二',
			'星期三',
			'星期四',
			'星期五',
			'星期六',
		];
		const date = new Date(dateStr);
		return weekDays[date.getDay()];
	} catch (e) {
		return '';
	}
}
export default class NumberPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectTab: 1,
			tabList: [],
			nums: new Array(7).fill(0).map((item, idx) => ''),
			pageList: [],
		};
	}

	componentDidMount() {
		this.getNumberSysFn();
	}

	saveNumberrFn = async () => {
		const isNull = this.state.nums.some((item) => !item);
		if (isNull) return alert('不能为空');
		const res = await saveNumber({
			num: this.state.nums.join(','),
			type: this.state.selectTab,
		});
		if (res.code === 100) {
			alert('保存成功');
			this.getNumberSysFn();
		}
	};

	getNumberSysFn = async () => {
		const res = await getNumberSys({
			pageNum: 1,
			pageSize: 30,
			type: this.state.selectTab || 1,
		});
		if (res.code === 100 && res.data) {
			this.setState({
				pageList: res.data.map((item) => {
					return {
						...item,
						nums: item.num ? item.num.split(',') : [],
					};
				}),
			});
		}
	};

	clickTab = (item) => {
		if (item === this.state.selectTab) return;
		this.setState(
			{
				selectTab: item,
			},
			() => {
				this.getNumberSysFn();
			}
		);
	};
	numberChange = (index, e) => {
		const value = e.target.value;
		// console.log('saveNumber', index, value);
		if (!(value >= 1 && value <= 49) && value !== '') {
			return;
		}
		let nums = this.state.nums.map((item, idx) => {
			if (idx === index) return value;
			return item;
		});
		this.setState({
			nums,
		});
	};

	render() {
		const { selectTab, nums, pageList } = this.state;

		// console.log('selectTab', nums);
		return (
			<div className="number-page">
				{tabName && tabName.length > 0 && (
					<ul className="tab">
						{tabName.map((item, idx) => {
							if (!item) return null;
							return (
								<li
									key={idx + ''}
									onClick={() => this.clickTab(idx)}
									className={
										selectTab === idx ? 'active' : ''
									}
								>
									{item}
								</li>
							);
						})}
					</ul>
				)}

				{nums && nums.length && (
					<div className="my-main">
						{nums.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div
										key={index}
										id="w1"
										className="m0 item green"
									>
										<div id="m1" className="number ">
											<input
												onChange={(e) =>
													this.numberChange(index, e)
												}
												type="text"
												name="username"
												value={item}
											></input>
										</div>
										{/* <div id="m1x" className="shengxiao">
											<span>火/兔</span>
										</div> */}
									</div>
								</React.Fragment>
							);
						})}
						<button onClick={this.saveNumberrFn}>保存</button>
					</div>
				)}

				{pageList && pageList.length !== 0 && (
					<div className="my-table">
						<table>
							<thead>
								<tr>
									<td>名称</td>
									<td>期数</td>
									<td>号码</td>
								</tr>
							</thead>
							<tbody>
								{pageList.map((item) => {
									return (
										<tr key={item.id}>
											<td> {tabName[item.type]}</td>
											<td>
												{item.startDate &&
													item.startDate.replaceAll(
														'-',
														''
													)}
											</td>
											<td>
												<div>
													{item.nums &&
														item.nums.map(
															(num, idx) => {
																return (
																	<React.Fragment
																		key={
																			idx
																		}
																	>
																		{idx ===
																			6 && (
																			<div
																				key="20"
																				className="quan_add"
																			>
																				+
																			</div>
																		)}
																		<div
																			className="quan"
																			style={
																				item.baseList &&
																				item
																					.baseList[
																					idx
																				]
																					? {
																							backgroundColor:
																								item
																									.baseList[
																									idx
																								]
																									.color,
																					  }
																					: {}
																			}
																		>
																			<span className="quan_num">
																				{
																					num
																				}
																			</span>
																		</div>
																	</React.Fragment>
																);
															}
														)}
												</div>

												<div>
													{item.startTime}
													{getWeekDay(item.startTime)}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		);
	}
}
