import React, { Component } from 'react';
import './index.scss';
import {
	getNumber,
	getCurrentNumber,
	getNumberPic,
	getNumberBanner,
} from '../../request/api';
// import useCountdown from './useCountdown';
import TimeCountDown from './TimeCountDown';

const tabName = {
	1: '澳门时乐彩',
	2: '香港新彩',
	3: '澳门博彩',
	4: '香彩',
	5: '澳门特彩',
};
const timerText = ['正', '在', '进', '行', '搅', '珠', '中'];

function formateTimeStr(num) {
	return num < 10 ? `0${num}` : num;
}
function getWeekDay(dateStr) {
	try {
		const weekDays = [
			'星期天',
			'星期一',
			'星期二',
			'星期三',
			'星期四',
			'星期五',
			'星期六',
		];
		const date = new Date(dateStr);
		return weekDays[date.getDay()];
	} catch (e) {
		return '';
	}
}

function addOneDay(dateStr) {
	// 创建一个新的 Date 对象，避免直接修改原日期对象
	let newDate = new Date(dateStr);
	// 获取当前日期的天数并加一
	let day = newDate.getDate();
	newDate.setDate(day + 1);
	return newDate;
}

// 使用 Intl.DateTimeFormat 自定义日期格式
function formatDate(date) {
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const formatter = new Intl.DateTimeFormat('zh-CN', options); // 使用中文环境下的格式
	return formatter.format(date);
}
let timer7 = null;
export default class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectTab: {},
			tabList: [],
			pageList: [],
			showHistroy: false, // 是否显示history
			picList: [],
			banner: {},
			showDownTime: false,
		};
		this.downRef = React.createRef();
	}

	componentWillUnmount() {
		clearInterval(timer7);
	}
	componentDidMount() {
		this.getCurrentNumberFn('init');
		this.getNumberBannerFn();
	}
	// 当前号码
	getCurrentNumberFn = async (type) => {
		const res = await getCurrentNumber({});
		// console.log(res);
		if (res.code === 100 && res.data) {
			const tabList = res.data.map((item, idx) => {
				// if (idx === 0) item.thisTime = '2024-11-05 21:46:00';
				// else if (idx === 2) item.thisTime = '2024-11-05 00:31:00';
				// item.startDate = '2024-11-05';
				const startDateAddOne = formatDate(
					addOneDay(item.startDate)
				).replaceAll('/', '-');

				console.log('startDateAddOne', startDateAddOne);
				return {
					...item,
					nums: item.num ? item.num.split(',') : [],
					time: item.thisTime ? item.thisTime.split(' ')[1] : '',
					timer: new Date(item.thisTime).getTime(),
					startDateAddOne, // startData加1天
				};
			});
			// 不是初始化
			if (type !== 'init') {
				const selectTab = tabList.find(
					(item) => item.type === this.state.selectTab.type
				);
				// tab数据刷新
				this.setState({
					tabList,
					selectTab,
				});
			} else {
				this.setState({
					tabList,
				});
				// 初始化页面的时候，默认选中第一个tab
				this.clickTab(tabList[0], 'init');
			}
		}
	};

	// 头部图片
	getNumberBannerFn = async () => {
		const res = await getNumberBanner();
		if (res.code === 100 && res.data) {
			this.setState({
				banner: res.data,
			});
		}
	};
	// 底部图片
	getNumberPicFn = async () => {
		const res = await getNumberPic({
			type: this.state.selectTab.type || 1,
		});
		// console.log(res);
		if (res.code === 100 && res.data) {
			this.setState({
				picList: res.data,
			});
		}
	};
	// 历史记录
	getNumberFn = async () => {
		const res = await getNumber({
			pageNum: 1,
			pageSize: 30,
			type: this.state.selectTab.type || 1,
		});
		// console.log(res);
		if (res.code === 100 && res.data) {
			this.setState({
				pageList: res.data.map((item) => {
					return {
						...item,
						nums: item.num ? item.num.split(',') : [],
					};
				}),
			});
		}
	};

	clickTab = (item, type) => {
		// console.log('55555', this.downRef.current?.desctory);
		if (item.type === this.state.selectTab.type) return;
		// if (this.downRef.current) {
		// 	this.downRef.current.desctory();
		// }

		// this.setState(
		// 	{
		// 		showDownTime: false,
		// 	},
		// 	() => {
		this.setState(
			{
				selectTab: item,
				showHistroy: false,
				showDownTime: true,
			},
			() => {
				type !== 'init' && this.getCurrentNumberFn();
				this.getNumberFn();
				this.getNumberPicFn();
				this.startDownTime();
			}
		);
		// }
		// );
	};
	// 倒计时完成
	onTimeout = () => {
		console.log('onTimeout');
		// this.forceUpdate();
		this.setState({
			selectTab: this.state.selectTab,
		});

		// 开启定时器出现数据
		this.startDownTime();

		// 重新获取当前号码数据
		this.getCurrentNumberFn();
	};

	// 倒计时中
	onTimeinner = () => {
		// console.log(555555555555);
		// const t1 = (Date.now() - timer) / 1000;
		const downTimer = this.state.selectTab.timer - Date.now();
		// 倒计时前5分钟显示遮盖层
		if (downTimer > 0 && downTimer <= 1000 * 60 * 5) {
			console.log(5555555555, downTimer);
			this.setState({
				selectTab: this.state.selectTab,
			});
		}
	};

	// 开启每7s倒计时
	startDownTime() {
		const timer = this.state.selectTab.timer;
		const t1 = (Date.now() - timer) / 1000;
		// 不小于49秒
		if (!(t1 > 0 && t1 <= 49)) return;
		// 存在移除
		if (timer7) clearInterval(timer7);

		// 小于49秒开启定时器
		timer7 = setInterval(() => {
			// 刷新页面
			this.setState({
				selectTab: this.state.selectTab,
			});
			const t2 = (Date.now() - timer) / 1000;
			console.log(t2, 't2', !(t2 > 0 && t2 <= 49));
			// 倒计时结束，不用再利用倒计时了
			if (!(t2 > 0 && t2 <= 49)) {
				console.log(t2, 't33');
				clearInterval(timer7);
			}
		}, 7000);
	}

	handleShowHistroy = () => {
		this.setState({
			showHistroy: !this.state.showHistroy,
		});
	};

	render() {
		const {
			tabList,
			selectTab,
			pageList,
			showHistroy,
			picList,
			banner,
			showDownTime,
		} = this.state;

		// 倒计时时间
		const downTimer = selectTab.timer - Date.now();

		return (
			<div className="home-page">
				{banner && banner.image ? (
					<div className="bg">
						<img src={banner.image} alt=""></img>
					</div>
				) : (
					<div className="default_bg"></div>
				)}
				{tabList && tabList.length > 0 && (
					<ul className="tab">
						{tabList.map((item) => {
							return (
								<li
									key={item.id}
									onClick={() => this.clickTab(item)}
									className={
										selectTab.type === item.type
											? 'active'
											: ''
									}
								>
									{tabName[item.type]}
								</li>
							);
						})}
					</ul>
				)}
				{selectTab && (
					<ul className="head">
						<li>
							{tabName[selectTab.type]} 第
							<span className="redText">
								{downTimer < 0 &&
									selectTab.startDate &&
									selectTab.startDate.replaceAll('-', '')}

								{downTimer > 0 &&
									selectTab.startDateAddOne &&
									selectTab.startDateAddOne.replaceAll(
										'-',
										''
									)}
							</span>
							期
						</li>
						{/* 倒计时 */}
						<li className="djs">
							{selectTab.timer && downTimer > 0 && (
								<TimeCountDown
									ref={this.downRef}
									time={selectTab.timer}
									onTimeout={() => {
										this.onTimeout();
									}}
									onTimeinner={() => {
										this.onTimeinner();
									}}
									render={({ day, hour, minute, second }) => {
										return (
											<span>
												{day > 0 ? `${day}天` : ''}
												{formateTimeStr(hour)}:
												{formateTimeStr(minute)}:
												{formateTimeStr(second)}
											</span>
										);
									}}
								></TimeCountDown>
							)}
						</li>
						<li>
							<div
								className="redText"
								onClick={this.handleShowHistroy}
							>
								历史记录
							</div>
						</li>
					</ul>
				)}

				{selectTab && selectTab.nums && (
					<React.Fragment>
						{/* 前5分钟 */}
						{downTimer > 0 && downTimer <= 1000 * 60 * 5 ? (
							<div className="my-main">
								{timerText &&
									timerText.map((item, idx) => {
										return (
											<React.Fragment key={Math.random()}>
												{idx === 6 && (
													<div
														key="20"
														className="item bg_item"
													>
														<span></span>
													</div>
												)}
												<div
													key={idx}
													className="item bg_item"
												>
													{item}
												</div>
											</React.Fragment>
										);
									})}
							</div>
						) : (
							<div className="my-main">
								{selectTab.nums &&
									selectTab.nums.map((item, index) => {
										// 倒计时前index*7 s中
										let t1 =
											(Date.now() - selectTab.timer) /
											1000;
										// t1 = 46;

										const isFlag =
											t1 > 0 && t1 < (index + 1) * 7;
										// 显示背景
										if (isFlag) {
											return (
												<React.Fragment
													key={Math.random()}
												>
													{index === 6 && (
														<div
															key="20"
															className="item bg_item"
														>
															<span></span>
														</div>
													)}
													<div
														key={index}
														className="item bg_item"
													>
														{timerText[index]}
													</div>
												</React.Fragment>
											);
										}

										return (
											<React.Fragment key={Math.random()}>
												{index === 6 && (
													<div
														key="20"
														className="item"
													>
														<span></span>
													</div>
												)}
												<div
													key={item}
													className="m0 item"
													style={
														selectTab.baseList &&
														selectTab.baseList[
															index
														]
															? {
																	backgroundColor:
																		selectTab
																			.baseList[
																			index
																		].color,
															  }
															: {}
													}
												>
													<div className="number">
														{item}
													</div>
													{selectTab.baseList &&
														selectTab.baseList[
															index
														] && (
															<div className="shengxiao">
																<span>
																	{
																		selectTab
																			.baseList[
																			index
																		]
																			.element
																	}
																	/
																	{
																		selectTab
																			.baseList[
																			index
																		].zodiac
																	}
																</span>
															</div>
														)}
												</div>
											</React.Fragment>
										);
									})}
							</div>
						)}

						<div className="my-remark">
							第
							{selectTab.startDate &&
								selectTab.startDate.replaceAll('-', '')}
							期&nbsp; {selectTab.startTime}
							{getWeekDay(selectTab.startTime)}
						</div>
					</React.Fragment>
				)}

				{showHistroy && pageList && pageList.length !== 0 && (
					<div className="my-table">
						<table>
							<thead>
								<tr>
									<td>名称</td>
									<td>期数</td>
									<td>号码</td>
								</tr>
							</thead>
							<tbody>
								{pageList.map((item) => {
									return (
										<tr key={item.id}>
											<td> {tabName[item.type]}</td>
											<td>
												{item.startDate &&
													item.startDate.replaceAll(
														'-',
														''
													)}
											</td>
											<td>
												<div>
													{item.nums &&
														item.nums.map(
															(num, idx) => {
																return (
																	<React.Fragment
																		key={
																			idx
																		}
																	>
																		{idx ===
																			6 && (
																			<div
																				key="20"
																				className="quan_add"
																			>
																				+
																			</div>
																		)}
																		<div
																			className="quan"
																			style={
																				item.baseList &&
																				item
																					.baseList[
																					idx
																				]
																					? {
																							backgroundColor:
																								item
																									.baseList[
																									idx
																								]
																									.color,
																					  }
																					: {}
																			}
																		>
																			<span className="quan_num">
																				{
																					num
																				}
																			</span>
																		</div>
																	</React.Fragment>
																);
															}
														)}
												</div>

												<div>
													{item.startTime}
													{getWeekDay(item.startTime)}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
				{!showHistroy && picList && picList.length !== 0 && (
					<div className="pic-list">
						{picList.map((item) => {
							return (
								<div key={item.id}>
									<img
										src={item.image}
										alt=""
										loading="lazy"
									></img>
								</div>
							);
						})}
					</div>
				)}

				{/* <div className="my-main">
					<div className="m0 item green">
						<div className="number ">
							38{' '}
						</div>
						<div  className="shengxiao">
							<span>火/兔</span>
						</div>
					</div>
					<div className="m0 item red">
						<div className="number ">
							46
						</div>
						<div className="shengxiao">
							<span>火/羊</span>
						</div>
					</div>
					<div  className="m0 item blue">
						<div className="number ">
							04{' '}
						</div>
						<div className="shengxiao">
							<span>土/牛</span>
						</div>
					</div>
					<divclassName="m0 item blue">
						<div className="number ">
							15{' '}
						</div>
						<div className="shengxiao">
							<span>木/虎</span>
						</div>
					</divclassName=>
					<div  className="m0 item green">
						<div className="number ">
							43{' '}
						</div>
						<div className="shengxiao">
							<span>水/狗</span>
						</div>
					</div>
					<div className="m0 item green">
						<div  className="number ">
							33{' '}
						</div>
						<div className="shengxiao">
							<span>金/猴</span>
						</div>
					</div>
					<div  className="item">
						<span></span>
					</div>
					<div className="m0 item blue">
						<div  className="number ">
							47{' '}
						</div>
						<div className="shengxiao">
							<span>火/马</span>
						</div>
					</div>
				</div> */}
			</div>
		);
	}
}
