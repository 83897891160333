import fetch from './fetch';
// import axios from "./axios";

// 分页号码
export const getNumber = (data) => {
	return fetch({
		url: '/noauth/number',
		data,
		method: 'GET',
	});
};
// 保存号码
export const saveNumber = (data) => {
	return fetch({
		url: '/noauth/number',
		data,
	});
};
// 本期号码
export const getCurrentNumber = (data) => {
	return fetch({
		url: '/noauth/number/current',
		data,
		method: 'GET',
	});
};
// 图片列表
export const getNumberPic = (data) => {
	return fetch({
		url: '/noauth/number/pic',
		data,
		method: 'GET',
	});
};
// 获取号码列表
export const getNumberSys = (data) => {
	return fetch({
		url: '/noauth/number/sys',
		data,
		method: 'GET',
	});
};

// 保存图片
export const saveNumberPic = (data) => {
	return fetch({
		url: `/noauth/number/pic?image=${data.image}&type=${data.type}`,
		data,
	});
};
// 删除图片
export const deleteNumberPic = (id) => {
	return fetch({
		url: `/noauth/number/pic/${id}`,
		method: 'DELETE',
	});
};
// 根据类型删除图片
export const deleteNumberPicByType = (id) => {
	return fetch({
		url: `/noauth/number/picByTYpe?type=${id}`,
		method: 'DELETE',
		data: { type: id },
	});
};

// banner
export const getNumberBanner = (data) => {
	return fetch({
		url: '/noauth/number/banner',
		data,
		method: 'GET',
	});
};
// 保存banner
export const saveNumberBanner = (data) => {
	return fetch({
		url: `/noauth/number/banner?image=${data.image}`,
		data,
	});
};
// 上传文件
export const uploadFile = (data) => {
	return fetch({
		url: '/noauth/number/upload',
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};
